import React, { Component } from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './Personalinfo.scss';

export default class Personalinfo extends Component {
    render() {
        return (
            <div className="personalInfo">
                <Container>
                <Row>
                    <Col md={12}>
                        <div className="headText">Personal Info</div>
                    </Col>
                    <Col md={3} className="m-t-2">Name:</Col>
                    <Col md={9} className="m-t-2">Rakesh Yadav</Col>

                    <Col md={3}>Father Name:</Col>
                    <Col md={9}>Lt. Shree Bramhanand Yadav</Col>

                    <Col md={3}>DOB:</Col>
                    <Col md={9}>1-Jul-1994</Col>

                    <Col md={3}>Marital Status:</Col>
                    <Col md={9}>Single</Col>

                    <Col md={3}>Address:</Col>
                    <Col md={9}>B-2/B Sewak Park Dwarka Mor New Delhi 110059 India.</Col>

                    <Col md={12}>
                        <div className="m-t-2 signName">I hereby declare that all the information provided here are true to the best of my knowledge.</div>
                        <div className="m-b-d m-t-1 signName">Rakesh Yadav</div>
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}
