import React, { Component } from 'react';
import './Header.scss';
import { Container, Row, Col } from 'react-bootstrap';

class Header extends Component {
    render() {
        return (
            <Container fluid>
                <Row className="headerBox">
                    <Col md={12}>
                    <div className="nameText">Rakesh Yadav</div>
                    <div className="positionText">Software Developer</div>
                    </Col>
                </Row>
             </Container>
        )
    }
}

export default Header;