import React, { Component } from 'react';
import Header from './Component/Header/Header';
import Sidebar from './Component/Sidebar/Sidebar';
import EducationComp from './Component/Education/Education';
import { Container, Row, Col } from 'react-bootstrap';
import WorkExp from './Component/WorkExp/WorkExp';
import './App.scss';
import Personalinfo from './Component/Personal/Personalinfo';


class App extends Component {
  render() {
    return (
      <div className="appBox">
         <Header/>
        <Container fluid>
       
          <Row>
            <Col className="p-r-n p-l-n" md={3}><Sidebar/></Col>
            <Col className="contextArea p-r-n3" md={9}>
              <WorkExp/>
              <EducationComp/>
              <Personalinfo/>
            </Col>
            <Col md={12} className="p-r-l-d">
            <div className="footerBar"></div>
            </Col>
            
          </Row>
          
        </Container>
        
      </div>
      // end of appBox
    );
  }
}

export default App;
