import React, { Component } from 'react';
import {Row, Col, Container } from "react-bootstrap";
import './Workexp.scss';

export default class WorkExp extends Component {
    render() {
        return (
            <div className="workexpBox">
                <Container>
                <Row>
                    <Col md={12}>
                        <h2 className="headText">Work Experience</h2>
                    </Col>
                    <Col md={12}>
                        <div className="subHead">Shiva Infotech Innovations Pvt. Ltd.</div>
                        <span className="profileText">Software Engineer</span><span className="timeText">(Jun 2018 to Present)</span>
                    </Col>
                    <Col md={12}>
                        <div> <span className="projectTitle">Project:</span> BookMyOrder</div>
                        <ul className="kraBox">
                        <li className="ConText"><span className="projectHead">Description: </span> 
                         BookMyOrder is a mobile Application specially build for the exporter, 
                         where exporter easily books their customer inquiry offline and sent all inquiry
                          to the portal when comes to the stable internet connection.
                         </li>
                         
                         <li className="ConText"><span className="projectHead">Responsibility: </span>  
                        Design, Analysis, Api Integration and publish to Google Play Store and App Store.
                        </li>

                        <li className="ConText"><span className="projectHead">Skill Used: </span>  
                        Ionic, Html, Scss, Angular, Gradle, Android Studio, Cordova
                        </li>

                        </ul>
                    </Col>
                    <Col md={12} className="">
                        <div> <span className="projectTitle">Project:</span> Turbo ERP App</div>
                        <ul className="kraBox">
                        <li className="ConText"><span className="projectHead">Description: </span> 
                        Turbo ERP is a unique web portal to manage the import, export and trading of goods
                        and buyer vendors GST as well as inventory and CRM management for multiple clients.
                         </li>

                        <li className="ConText"><span className="projectHead">Responsibility: </span>  
                        Designing and Api Integration for Turbo ERP App.
                        </li>

                        <li className="ConText"><span className="projectHead">Skill Used: </span>  
                        ReactJS, Redux, SCSS, StoryBook,
                        </li>
                        
                        </ul>
                    </Col>

                    <Col md={12}>
                        <div> <span className="projectTitle">Project:</span> Various Portals & Websites </div>
                        <ul className="kraBox">
                        <li className="ConText"><span className="projectHead">Portal: </span> 
                         TataSky, Den Cable Network, Turbo-CRM, BTI Payments Private Limited (India 1 ATM)
                         </li>
                         <li className="ConText"><span className="projectHead">Website: </span> 
                            Hosley.in, Neurologysleepcentre.com, Assm.in, Shivainfotech.com, Turboesigner.com etc..
                         </li>
                        </ul>
                    </Col>

                    <Col md={12}>
                        <div className="subHead">Realbox Data Analytics Pvt. Ltd.</div>
                        <span className="profileText">Software Design Engineer</span><span className="timeText">(Nov 2017 to May 2018)</span>
                    </Col>

                    <Col md={12}>
                        <div> <span className="projectTitle">Project:</span> Car Street CRM</div>
                        <ul className="kraBox">
                        <li className="ConText"><span className="projectHead">Description: </span> 
                         Design & Testing Carstreet CRM Application with HTML, CSS, SCSS, Bootstrap, JavaScript.
                         </li>
                        </ul>
                    </Col>

                    <Col md={12}>
                        <div> <span className="projectTitle">Project:</span> Gossip The Cafe</div>
                        <ul className="kraBox">
                        <li className="ConText"><span className="projectHead">Description: </span> 
                         Developing various website for Gossip The Cafe the premium dessert boutique based in Dubai.
                         </li>
                        </ul>
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}
