import React, { Component } from "react";
import "./Sidebox.scss";
import {Container, Row, Col } from "react-bootstrap";

class Sidebar extends Component {
  render() {
    return (
      <div className="sideBox">
        <div className="aboutBox">
          <div className="infoHead">
            <i className="far fa-user iconSize" />Career Objective
          </div>
          <p className="infoContext">
          To work in a durative and challenging environment where I could constantly erudite and fruitfully deliver
          solutions to problems and executive successfully every given task through my sincerity and workmanship.
          </p>
        </div>

        <div className="skillBox">
          <div className="infoHead"> <i className="far fa-bookmark iconSize"></i> Development Skills</div>
          <div className="skillContext">
          <Container>
          <Row className="skillRow">
            <Col md={6}>HTML</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>CSS</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>SCSS</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>Bootstrap</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>JavaScript</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>React JS</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>React Native</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>Redux</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>Storybook</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>TypeScript</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        
        <Row className="skillRow">
            <Col md={6}>Angular</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>Ionic</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        
        <Row className="skillRow">
            <Col md={6}>Wordpress</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>SEO</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        
        </Container>

        </div>
      </div>

      <div className="langBox m-t-1">
          <div className="infoHead"> <i className="fas fa-language iconSize"></i> Languages</div>
          <div className="skillContext">
          <Container>
          <Row className="skillRow">
            <Col md={6}>Hindi</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        <Row className="skillRow">
            <Col md={6}>English</Col>
            <Col md={6}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </Col>
        </Row>
        </Container>

        </div>
      </div>

      <div className="interestBox m-t-2">
          <div className="infoHead"> <i className="fab fa-gratipay iconSize"></i> Interested</div>
          <div className="intContext">
          <Container>
          <Row >
            <Col md={3}>
              <i className="fas fa-headphones-alt intIcon"></i>
            </Col>
            <Col md={3}>
              <i className="fas fa-globe intIcon"></i>
            </Col>
            <Col md={3}>
              <i className="fas fa-film intIcon"></i>
            </Col>
            <Col md={3}>
              <i className="fas fa-book-reader intIcon"></i>
            </Col>
        </Row>
        </Container>

        </div>
      </div>

      <div className="contactBox m-t-2">
      <div className="infoHead">
      <i className="fas fa-info-circle iconSize" /> Contact Info
    </div>
    <p className="contactContext"><i className="fas fa-mobile-alt iconContact" />+91-9450839093</p>
    <p className="contactContext"><i className="fas fa-envelope-open iconContact" />rakesh0111@hotmail.com</p>
    <p className="contactContext"> <i className="fab fa-linkedin iconContact" />linkedin.com/in/hellorakesh/</p>
    <p className="contactContext">
      <a href="https://github.com/digitalrakesh">
      <i className="fab fa-github iconContact" />
       github.com/digitalrakesh
       </a>
       </p>
    </div>
    </div>

    );
  }
}

export default Sidebar;
