import React, { Component } from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import './Education.scss';


class EducationComp extends Component {
  render() {
    return (
      <div className="educationBox">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="headText">Education</h1>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
            <Table bordered>
                <thead>
                  <tr>
                    <th>Qualification</th>
                    <th>Board / Institute</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>MCA</td>
                    <td>Swami Vivekanand Subharti University Meerut UP</td>
                    <td>Pursuing</td>
                  </tr>
                  <tr>
                    <td>B. com</td>
                    <td>Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur UP</td>
                    <td>2015</td>
                  </tr>
                  <tr>
                    <td>Intermediate</td>
                    <td>UP Board</td>
                    <td>2012</td>
                  </tr>
                  <tr>
                    <td>High School</td>
                    <td>UP Board</td>
                    <td>2010</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col md={12} >
              <h1 className="headText">Professional Education</h1>
            </Col>
            <Col md={12} className="eduBox">
              <span className="headContext">Diploma</span>
              <span className="yearText">2014</span>
              <div className="mainContext">Diploma in Computer Application from NLCE Lucknow</div>
            </Col>
          </Row>

          <Row>
          <Col md={12} className="eduBox">
              <span className="headContext">Certification</span>
              <span className="yearText">2012</span>
              <div className="mainContext">Certificate in Information Technology from IICL</div>
            </Col>
          </Row>

          </Container>
      </div>
    );
  }
}

export default EducationComp;
